import * as qs from "qs";
import api from "../axiosInstances/axiosAnalytics";
import strapi from "../axiosInstances/axiosStrapi";

import { PigeonResponse } from "../response";
import { defaultReturnFields } from "../../utils/summaryReturnFields";
import { escape, parseStrapiRes } from "./utils";
import { preparePlatformForApi } from "utils/platform";

const getBaseURL = () => {
  // Check if its running in a branch
  if (process.env.REACT_APP_BRANCH_PATH) {
    return (
      process.env.REACT_APP_STRAPI_API.replace("/apiv4", "") +
      process.env.REACT_APP_BRANCH_PATH
    );
  }

  // Check if its running in a dev environment
  return window.location.origin.indexOf("localhost") > -1 ||
    window.location.origin.indexOf("cdn") > -1
    ? "https://staging.blackbirdailabs.com"
    : window.location.origin;
};

// Narrative Feed Status Callback URL
const NARRATIVE_FEED_URL = getBaseURL();

// TODO rename "db" to "projectName"

// LOGIN
export const login = async (username, password) => {
  const res = await strapi.post("/api/auth/local", {
    identifier: username,
    password,
  });

  return escape(res.data);
};

export const forgotPassword = async (email) => {
  const res = await strapi.post("/api/auth/forgot-password", {
    email,
  });
  return escape(res.data);
};

export const resetPassword = async (code, password, passwordConfirmation) => {
  const res = await strapi.post("/api/auth/reset-password", {
    code,
    password,
    passwordConfirmation,
  });
  return escape(res.data);
};

// export const deleteDataConnectorJob = async (id) => {
//   const response = await strapi.delete(`/api/data-connector-jobs/${id}`);
//   return response.data || null;
// };

export const getFiltersAPI = async () => {
  const query = qs.stringify({
    populate: {
      custom_options: {
        populate: "*",
      },
      field: {
        populate: "*",
      },
      platforms: {
        populate: "*",
      },
    },
    pagination: {
      pageSize: 50,
    },
  });

  const route = `/api/filters/?${query}`;

  try {
    const response = await strapi.get(route);
    const responseArr = parseStrapiRes(response.data);
    return responseArr;
  } catch (e) {
    return [];
  }
};

export const getModelLabelsAPI = async () => {
  const route = `/api/model-labels`;
  try {
    const response = await strapi.get(route);
    const responseArr = parseStrapiRes(response.data);
    return responseArr || [];
  } catch {
    return [];
  }
};

export const getFieldEntries = async ({ db, field, platform }) => {
  try {
    const response = await api.post(
      `${db}/field-entries/${field}`,
      preparePlatformForApi({ platform })
    );
    return response?.data?.body || [];
  } catch {
    return [];
  }
};

export const getCustomAPI = async ({ db, customEndpoint, platform }) => {
  try {
    const response = await api.post(
      `${db}/${customEndpoint}`,
      preparePlatformForApi({ platform })
    );
    return response?.data?.body || [];
  } catch {
    return [];
  }
};

export const getProject = async (projectName, config) => {
  const response = await api.get(`/projects?name=${projectName}`, config);
  return escape(response.data.body);
};

export const getUserCohorts = async (projectName) => {
  try {
    const response = await api.post(`/${projectName}/userCohorts`);
    return escape(response.data.body);
  } catch {
    return [];
  }
};

export const checkDataValidityForProject = async (db, fieldsToCheck) => {
  try {
    const response = await api.post(`/field-exists/${db}`, fieldsToCheck);
    return response.data.body;
  } catch {
    return false;
  }
};

export const createNarrative = async (data) => {
  const response = await api.post("/narrative", data, {
    clearCacheEntry: true,
  });
  return response.data.body || {};
};

export const getNarratives = async (projectName, config) => {
  const response = await api.post("/narrative/list", { projectName }, config);
  return response.data?.body || [];
};

export const getPlatforms = async (projectName, config) => {
  const response = await api.post(`/${projectName}/platforms`, {}, config);
  return response.data?.body || [];
};

export const updateNarrative = async (data) => {
  const response = await api.put("/narrative", data, { clearCacheEntry: true });
  return response.data.body;
};

export const deleteNarrative = async (data) => {
  const response = await api.delete("/narrative", { data });
  return response.data.body;
};

// Concepts
export const loadConcepts = async ({ db, req = {}, config }) => {
  const response = await api.post(
    `/${db}/phrases/usage`,
    preparePlatformForApi(req),
    config
  );
  return response.data.body || [];
};

export const loadConceptsRelated = async ({ db, req = {}, config }) => {
  const response = await api.post(
    `/${db}/phrases/related`,
    preparePlatformForApi(req),
    config
  );
  return response.data.body || [];
};

// POSTS

export const loadPostsKeywords = async ({ db, req = {}, config }) => {
  const response = await api.post(
    `/${db}/clusters`,
    preparePlatformForApi(req),
    config
  );
  return response.data.body || [];
};

export const loadPosts = async ({ db, req = {}, config }) => {
  const response = await api.post(
    `/${db}/posts`,
    preparePlatformForApi(req),
    config
  );
  return response.data.body || [];
};

// ENTITIES

export const loadUsersKeywords = async ({ db, req = {}, config }) => {
  const response = await api.post(
    `/${db}/users`,
    preparePlatformForApi(req),
    config
  );
  return response.data.body || [];
};

export const loadParentUsersKeywords = async ({ db, req = {}, config }) => {
  const response = await api.post(
    `/${db}/parents`,
    preparePlatformForApi(req),
    config
  );
  return response.data.body || [];
};

export const loadHashtagsKeywords = async ({ db, req = {}, config }) => {
  const response = await api.post(
    `/${db}/hashtags`,
    preparePlatformForApi(req),
    config
  );
  return response.data.body || [];
};

export const loadUrls = async ({ db, req = {}, config }) => {
  const response = await api.post(
    `/${db}/urls`,
    preparePlatformForApi(req),
    config
  );
  return response.data.body || [];
};

export const loadSubreddits = async ({ db, req = {}, config }) => {
  const response = await api.post(
    `/${db}/subreddit`,
    preparePlatformForApi(req),
    config
  );
  return response.data.body || [];
};

export const loadActivity = async ({ db, req = {}, config }) => {
  const response = await api.post(
    `/${db}/activity`,
    {
      ...preparePlatformForApi(req),
      start_date: req.start_date?.replace("Z", req.time_zone),
      end_date: req.end_date?.replace("Z", req.time_zone),
    },
    config
  );
  return response.data.body || {};
};

export const loadReportActivity = async ({ db, req = {}, config }) => {
  try {
    const response = await api.post(
      `/${db}/reportActivity`,
      {
        ...preparePlatformForApi(req),
        start_date: req.start_date?.replace("Z", req.time_zone),
        end_date: req.end_date?.replace("Z", req.time_zone),
      },
      config
    );
    return response.data.body || {};
  } catch {
    return {};
  }
};

export const fetchNarrativeFeed = async (projectName) => {
  const response = await api.post(
    `/narrativeFeed/${projectName}/fetch`,
    {},
    { clearCacheEntry: true }
  );
  return response.data.body;
};

export const generateNarrativeFeed = async (projectName) => {
  const response = await api.post(
    `/narrativeFeed/${projectName}/generate`,
    {
      baseUrl: NARRATIVE_FEED_URL,
    },
    { clearCacheEntry: true }
  );
  return response.data.body;
};

export const loadSummary = async ({ db, req = {}, config = {} }) => {
  const response = await api.post(
    `/${db}/summary`,
    { returnFields: defaultReturnFields, ...preparePlatformForApi(req) },
    config
  );
  return response.data.body || [];
};

export const loadSummaryUserGroups = async ({ db, requests }) => {
  return Promise.all(
    requests.map((req) =>
      api.post(`/${db}/summary`, { returnFields: defaultReturnFields, ...req })
    )
  ).then((resp) => {
    const body = requests.reduce((obj, d, index) => {
      if (resp[index].data.body) {
        obj[d.user_group_names[0]] = resp[index].data.body[0]?.user_ct;
      }
      return obj;
    }, {});
    const response = new PigeonResponse(body, true);
    return {
      data: response,
    };
  });
};

// HOAX
// export const loadHoaxDetails = async ({ db, req = {}, config }) => {
//   const response = await api.post(`/${db}/hoaxes`, req, config);
//   return response.data.body;
// };

// MISC
export const getZendeskToken = () => {
  return api
    .get("/zendeskauth")
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      console.error(err);
    });
};

export const getOrganizationPostCountByPlatform = async () => {
  try {
    const response = await api.get("/organization/countByPlatform");
    return response.data.body;
  } catch {
    return {};
  }
};

export const getOrganizationPostCountCap = async () => {
  try {
    const response = await api.get("/organization/post_count_cap");
    return response.data.body;
  } catch {
    return {};
  }
};

// export const getLabels = async (projectName, labelType, config) => {
//   try {
//     const response = await api.post(
//       `/${projectName}/models/${labelType}/labels`,
//       {},
//       config
//     );
//     return response.data.body || {};
//   } catch {
//     return {};
//   }
// };

export const getModelVersions = ({ db }) => {
  return api
    .post(`/${db}/modelVersions`)
    .then(({ data }) => {
      return escape(data.body);
    })
    .catch((err) => {
      console.error(err);
      return null;
    });
};

export const getChildKeys = ({ db, parent, platform, classifierProfile }) => {
  return api
    .post(`/${db}/child-keys/${parent}/${db}`, {
      ...preparePlatformForApi({ platform }),
      harmClassifier: classifierProfile,
    })
    .then(({ data }) => {
      return escape(data.body);
    })
    .catch((err) => {
      console.error(err);
      return null;
    });
};

export const clearProjectCache = async (projectName) => {
  try {
    const response = await api.get(`/clearCache/${projectName}`);
    return response.data;
  } catch {
    return null;
  }
};

export const createHarmProfile = async (data) => {
  const response = await api.post("/harmProfile", data);
  return response.data.body;
};

export const getHarmProfiles = async () => {
  try {
    const response = await api.get("/harmProfile/list");
    return response.data.body || [];
  } catch {
    return [];
  }
};

export const updateHarmProfile = async (data) => {
  const response = await api.put(`/harmProfile/${data.id}`, data);
  const results = response.data.body;
  return results && typeof results === "string" ? JSON.parse(results) : results;
};

export const deleteHarmProfile = async (harmProfileId) => {
  const response = await api.delete(`/harmProfile/${harmProfileId}`);
  return response.data.body;
};

export const setHarmProfile = async (harmProfileId) => {
  try {
    const response = await api.get(`/harmProfile/select/${harmProfileId}`);
    return response.data.body || {};
  } catch {
    return {};
  }
};

export const createClassifierProfile = async (data) => {
  const response = await api.post("/classifierProfile", data);
  return response.data.body;
};

export const getClassifierProfiles = async () => {
  try {
    const response = await api.get("/classifierProfile/list");
    return response.data.body;
  } catch {
    return [];
  }
};

export const updateClassifierProfile = async (data) => {
  const response = await api.put(`/classifierProfile/${data.id}`, data);
  return response.data.body;
};

export const setClassifierProfileAPI = async (classifierProfileId) => {
  try {
    const response = await api.get(
      `/classifierProfile/select/${classifierProfileId}`
    );
    return response.data.body;
  } catch {
    return null;
  }
};

// User Group
export const createUserGroup = async (data) => {
  const response = await api.post("/userGroup", data);
  return response.data.body || [];
};

export const getUserGroups = async (projectID) => {
  try {
    const response = await api.get(`/userGroup/list/${projectID}`);
    return response.data.body || [];
  } catch {
    return [];
  }
};

export const updateUserGroup = async (data) => {
  const response = await api.put("/userGroup", data);
  return response.data.body;
};

export const deleteUserGroup = async (userGroupId) => {
  const response = await api.delete("/userGroup", {
    data: { id: userGroupId },
  });
  return response.data.body;
};

// export const getLanguages = async (projectName) => {
//   try {
//     const response = await api.post(`/${projectName}/languages`, {
//       byPlatform: true,
//     });
//     return response.data.body;
//   } catch {
//     return [];
//   }
// };

export const getAINarratives = async ({ db, req }) => {
  try {
    const response = await api.post(
      `/${db}/ai-narratives`,
      preparePlatformForApi(req)
    );
    return response.data.body;
  } catch {
    return [];
  }
};

export const loadDomains = async ({ db, req }) => {
  try {
    const response = await api.post(
      `/${db}/domains`,
      preparePlatformForApi(req)
    );
    return response.data.body || [];
  } catch {
    return [];
  }
};
