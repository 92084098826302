import { formatElasticSearchLabel, formatNumberkANDcomma } from "utils/format";
import lodashObject from "lodash/object";

export const FILTER_TYPES = {
  min_max: "min_max",
  categorical: "categorical",
  boolean: "boolean",
  date: "date",
  custom: "custom",
};

export const DEFAULT_HARM_OPTIONS = [
  {
    label: "Low",
    value: "Low",
    num_threshold: 0.25,
    field: "low",
  },
  {
    label: "Medium",
    value: "Medium",
    num_threshold: 0.5,
    field: "medium",
  },
  {
    label: "High",
    value: "High",
    num_threshold: 0.75,
    field: "high",
  },
  {
    label: "Very High",
    value: "Very High",
    num_threshold: 1,
    field: "very_high",
  },
];

const checkIfExists = (val) => {
  return val !== "" && val !== undefined;
};

// No need to keep options for __filter reference
const cleanFilter = (filter) => {
  return {
    ...filter,
    options: [],
  };
};

export const getFilterObj = (filter, value, isDefaultFilter) => {
  // this empty object will be return as a signal to delete the filter
  // -1 will mean delete
  const empty = {
    [filter.field]: {
      real: {},
      pillText: "",
      active: false,
      value: -1,
      isDynamic: filter.isDynamic,
      elasticSearchField: filter.elasticSearchField,
      __filter: cleanFilter(filter),
    },
  };

  switch (filter.type) {
    case FILTER_TYPES.min_max: {
      let obj = {};

      if (checkIfExists(value[0]) && checkIfExists(value[1])) {
        obj = {
          ["min_" + filter.field]: +value[0],
          ["max_" + filter.field]: +value[1],
        };
      } else if (checkIfExists(value[0])) {
        obj = {
          ["min_" + filter.field]: +value[0],
        };
      } else if (checkIfExists(value[1])) {
        obj = {
          ["max_" + filter.field]: +value[1],
        };
      } else {
        return empty;
      }

      return {
        [filter.field]: {
          __filter: cleanFilter(filter),
          real: obj,
          active: true,
          pillText: filter.getPillText(filter, null, value),
          value,
          isDynamic: filter.isDynamic,
          elasticSearchField: filter.elasticSearchField,
          isDefaultFilter,
        },
      };
    }

    case FILTER_TYPES.boolean:
    case FILTER_TYPES.categorical: {
      const rootField = filter.field;

      // Boolean filter sends request with options like this: { bot_like: true, bbmi: true, toxicity: true }.
      const isBoolean = filter.boolean;

      // Multi select filter sends request with options [ 'low', 'medium', 'high' ].
      const isMultiSelect = filter.multi_select;

      // If isBoolean, include all options, otherwise only selected ones.
      const selectedOptions = isBoolean
        ? filter.options
        : filter.options.filter((d) => value.includes(d.value));

      if (!selectedOptions.length) {
        return empty;
      }

      let real = {};

      if (isBoolean) {
        // Populates object like { bot_like: true, bbmi: true, toxicity: true }
        selectedOptions.forEach((option) => {
          const selected = value.includes(option.value);

          let v = selected;

          // Handling inverted options
          // This is no longer used as all filters are non-inverted which means sending what was selected
          // But still supporting it because strapi option has inverted toggle
          if (option.inverted) {
            if (selected) v = undefined;
            else v = true;
          }

          real[option.field] = v;
        });
      }
      // Populates array like [ 'low', 'medium', 'high' ].
      else if (isMultiSelect) {
        real[rootField] = selectedOptions.map((option) => option.field);
      }
      // As a fallback, populate object like { bot_like: true, bbmi: true, toxicity: true }
      else {
        selectedOptions.forEach((option) => {
          real[option.field] = true;
        });
      }

      // Add properties inside of an object instead of at the top level,
      // if 'requestPropertyGroup' is set on the filter configuration.
      if (filter.requestPropertyGroup) {
        real = { [filter.requestPropertyGroup]: real };
      }

      return {
        [filter.field]: {
          __filter: cleanFilter(filter),
          pillText: filter.getPillText(filter, selectedOptions, value),
          real,
          active: true,
          value,
          isDynamic: filter.isDynamic,
          elasticSearchField: filter.elasticSearchField,
          isDefaultFilter,
        },
      };
    }
    default:
      return empty;
  }
};

const getMinMaxText = (filter, option, value) => {
  const [min, max] = value;

  let txt = "";

  if (min !== "" && max !== "") {
    txt = `${formatNumberkANDcomma(min)}-${formatNumberkANDcomma(max)}`;
  } else if (min !== "") {
    txt = `> ${formatNumberkANDcomma(min)}`;
  } else if (max !== "") {
    txt = `< ${formatNumberkANDcomma(max)}`;
  }

  if (txt) {
    return txt + " " + filter.title;
  }

  return "";
};

const getCategoricalText = (filter, option, selectedOptions) => {
  let firstTwo = selectedOptions.slice(0, 2);
  const rest = selectedOptions.slice(2);

  if (filter?.isFormatted) {
    firstTwo = firstTwo.map((f) => formatElasticSearchLabel(f));
  } else if (filter?.field?.includes("subreddit")) {
    firstTwo = firstTwo.map((f) => `r/` + f);
  }

  return (
    (filter?.inverted ? "Hiding " : "") +
    firstTwo.join(", ") +
    (rest.length ? ` +${rest.length}` : "")
  );
};

const getBooleanText = (filter) => {
  return filter.title;
};

const getDynamicFilterPillText = (filter, options, values) => {
  const prefix = filter.field === "ai_narratives" ? filter.title + ": " : "";
  return prefix + getCategoricalText(filter, options, values);
};

export const FILTER_GROUPS = {
  classifiers: {
    name: "classifiers",
    order: 2,
  },
  post_attributes: { name: "post attributes", order: 0 },
  authors: { name: "authors", order: 1 },
};

// TODO: create group field in strapi and remove this function
export const getFilterGroup = (filter) => {
  let group = null;
  switch (filter.title) {
    case "Language":
    case "Toxicity":
    case "Topics":
    case "Cohorts":
    case "AI Narratives":
    case "AI Narrative":
    case "Domain":
      group = FILTER_GROUPS.classifiers;
      break;

    case "Verification":
    case "Submission Score":
      group = FILTER_GROUPS.authors;
      break;

    case "Post type":
    case "Data type":
    case "All data":
    case "Anonymous Posts":
    case "Subreddit":
      group = FILTER_GROUPS.post_attributes;
      break;

    default:
      break;
  }

  return group;
};

export const createDynamicFilter = (dynamicFilter, selectedPlatforms) => {
  if (!dynamicFilter?.type) {
    return {};
  }

  // Filter options based on selected platforms
  const options = (dynamicFilter.options || []).filter((option) => {
    // If no platforms, show always
    if (!option.platforms?.length) {
      return true;
    }

    // If any of the option's platforms is selected
    return option.platforms.some((p) => {
      return selectedPlatforms.includes(p);
    });
  });

  const isCustom = !!options.length;

  switch (dynamicFilter.type) {
    case FILTER_TYPES.categorical:
      if (isCustom) {
        return {
          tooltip: "",
          getPillText: (filter, options, values) => {
            let retString = "";
            const activeOptions = options.filter((o) =>
              o.inverted ? !values.includes(o.value) : values.includes(o.value)
            );
            for (let i = 0; i < activeOptions.length; i++) {
              const option = activeOptions[i];
              retString += option.pillText;
              if (i < activeOptions.length - 1) {
                retString += ", ";
              }
            }
            if (!activeOptions || !activeOptions.length) {
              retString += dynamicFilter.title;
            }
            return retString;
          },
          boolean: dynamicFilter.boolType === "bool",
          multi_select: dynamicFilter.boolType === "category",
          options: options.map((fo) => {
            return {
              label: fo.display_name,
              field: fo.field,
              value: fo.display_name,
              pillText: fo.pill_text,
              inverted: fo.inverted,
              tooltip: "",
            };
          }),
        };
      }

      return {
        tooltip: "",
        multi_select: true,
        getPillText: getDynamicFilterPillText,
        options: [],
      };
    case FILTER_TYPES.min_max:
      return {
        getPillText: getMinMaxText,
        getTooltipText: getMinMaxText,
      };
    case FILTER_TYPES.boolean:
      return {
        tooltip: "",
        type: FILTER_TYPES.boolean,
        getPillText: getBooleanText,
        options: [],
      };
    case FILTER_TYPES.custom:
      return {
        title: "test",
        type: FILTER_TYPES.categorical,
        boolean: true,
        getPillText: (filter, option, value) => {
          const suffix =
            value.length > 0 ? " " + filter.title.toLowerCase() : "";
          return getCategoricalText(filter, option, value) + suffix;
        },
        options: options.map((fo) => {
          return {
            label: fo.display_name,
            field: fo.field,
            value: "Show comments",
            pillText: "Show comments",
            inverted: fo.inverted,
            tooltip: "",
          };
        }),
      };

    default:
      return {};
  }
};

// "serialize" is a misnomer
export const serializeFilterObj = (filterObjToBeSerialized) => {
  const serialized = {};
  for (const key in filterObjToBeSerialized) {
    lodashObject.assign(serialized, filterObjToBeSerialized[key].real || {});
  }

  serialized["dynamicFilters"] = getSerializedDynamicFilters(
    filterObjToBeSerialized
  );

  return {
    obj: filterObjToBeSerialized, // TODO there's no point in returning an argument
    serialized,
  };
};

function getSerializedDynamicFilters(filters) {
  let serializedDynamicFilters = undefined;
  const eligibleDynamicFilters = Object.values(filters).filter(
    (fil) =>
      fil.isDynamic &&
      fil.__filter &&
      fil.__filter.elasticSearchField &&
      fil.__filter.boolType
  );

  serializedDynamicFilters = eligibleDynamicFilters.map((df) =>
    createSerializedDynamicFilter(df, df.value)
  );
  return serializedDynamicFilters;
}

const createSerializedDynamicFilter = (filter, value) => {
  let valueArr = [value].flat();

  if (filter.__filter.type === FILTER_TYPES.min_max) {
    valueArr = [
      createSerializedDynamicFilterObj(filter, {
        min: value[0] ? value[0] : 0,
        max: value[1],
      }),
    ];
  } else {
    valueArr = value.map((v) => createSerializedDynamicFilterObj(filter, v));
  }
  return {
    field: filter.__filter.field,
    elasticSearchField: filter.__filter.elasticSearchField,
    boolType: filter.__filter.boolType,
    value: valueArr,
  };
};

function createSerializedDynamicFilterObj(filter, value) {
  let dynamicFilterField = filter.__filter.elasticSearchField;
  if (filter.__filter.isParent) {
    dynamicFilterField += "." + value;
  }

  return {
    elasticSearchField: dynamicFilterField,
    boolType: filter.__filter.boolType,
    isTunable: filter.__filter.isTunable,
    value: value,
  };
}

/**
 * Gets default filters
 * @param {Array} defaultFilters organization.defaultFilters from strapi
 * @param {Array} currentFilters all filters (dynamic + static)
 * @param {String[]} selectedPlatforms selected platforms
 * @returns default filters { obj, serialized: { dynamicFilters } }
 */
export const getDefaultFilters = (
  defaultFilters,
  currentFilters,
  selectedPlatforms
) => {
  const obj = defaultFilters.reduce((acc, { filter, value, platforms }) => {
    // If no platform matched, skip
    if (!platforms.some((p) => selectedPlatforms.includes(p))) {
      return acc;
    }

    const found = currentFilters.find((d) => d.title.includes(filter));

    if (found && found.options) {
      // Find value in options array
      const selectedValue = value
        .map((d) => found.options.find((x) => x.field === d)?.value)
        .filter((d) => d);

      return {
        ...acc,
        ...getFilterObj(found, selectedValue, true),
      };
    }

    return acc;
  }, {});

  return serializeFilterObj(obj);
};
