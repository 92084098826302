import CountUp from "react-countup";
import { ReactComponent as EngagementsIcon } from "icons/UI/Engagements.svg";
import { ReactComponent as AuthorsIcon } from "icons/UI/Authors.svg";
import { ReactComponent as UpDownIcon } from "icons/UI/ThumbsUpDown.svg";
import { ReactComponent as EmotionIcon } from "icons/UI/EmotionIcon.svg";
import { getPercent, roundToOne } from "utils/format";
import { descSort } from "utils/sort";
import {
  totalPosts,
  totalEngagements,
  totalUsers,
  emotions,
  sentiments,
  postHierarchy,
} from "utils/summaryReturnFields";
import {
  USER_LABEL,
  USER_COLUMN_LABEL,
  STATS_ROW_LIMITS,
  lookupPlatformConfigItem,
} from "utils/constants";
import { Box } from "@mui/material";
import { aggregateBreakdownValues, getBreakdownValues, getSummaryDataWithPlatform } from "utils/summary";

export const isNeutralEmotion = (field) => field.includes("neutral_emotion");
const isNeutralSentiment = (field) => field.includes("is_neutral");

const getStart = (value) => {
  return Math.floor(value / 2);
};

const countupSpeed = 1.1;

const getAuthorStatRows = ({
  authorGroupsChildren,
  userGroups,
  userCohorts,
}) => {
  const n = STATS_ROW_LIMITS.authors - authorGroupsChildren.length;
  const halfLen = Math.round(n * 0.5);
  const cohortsLen =
    userGroups.length > halfLen ? halfLen : n - userGroups.length;
  const _userCohorts = userCohorts.slice(0, cohortsLen);
  const _userGroups = userGroups.slice(0, n - _userCohorts.length);

  return {
    userGroups: _userGroups,
    userCohorts: _userCohorts,
  };
};

const sortedDetails = (
  selectedPlatforms,
  details,
  fieldsToSort,
  post_ct,
  field = "field_name",
  keepZero = false,
  summaryDataAllPlatforms
) => {
  return fieldsToSort
    .filter((d) => {
      if (keepZero) {
        return true;
      }
      return details[d[field]];
    })
    .map((d) => {
      // Fallback to 0 if null or undefined
      const v = details[d[field]] ?? 0;
      return {
        id: d.id,
        percent: post_ct ? roundToOne((v / post_ct) * 100) : null,
        value: v,
        breakdownValues: getBreakdownValues(selectedPlatforms, summaryDataAllPlatforms, d[field]),
        label: d.label,
        tooltipText: d.tooltipText,
        sort_bot:
          isNeutralEmotion(d.field_name) || isNeutralSentiment(d.field_name),
      };
    })
    .sort(descSort);
};

// Add the summary data for the selectedPlatforms provided
export const aggregateSummaryDataForselectedPlatforms = (summaryData, selectedPlatforms) => {
  if(selectedPlatforms[0] === selectedPlatforms.all) {
    return summaryData[selectedPlatforms.all];
  }
  const selectedPlatformsummaryData = {};
  // Iterate over the selectedPlatforms and add the summary data for each platform
  selectedPlatforms.forEach((platform) => {
    const data = summaryData[platform.value];
    // Loop through each property of the data and add it to the selectedPlatformsummaryData
    Object.keys(data).forEach((key) => {
      if (!selectedPlatformsummaryData[key]) {
        selectedPlatformsummaryData[key] = 0;
      }
      selectedPlatformsummaryData[key] += data[key];
    });
  });
  return selectedPlatformsummaryData;
};

// Extracts the summary data from the summary response
export const getSummaries = ({
  selectedPlatforms,
  summaryDataAllPlatforms,
  details,
  loading = false,
  userGroups,
  userCohorts,
}) => {
  // Get the summary data for the selected platform
  const summaryData = getSummaryDataWithPlatform(summaryDataAllPlatforms, selectedPlatforms);

  const {
    post_ct: posts,
    user_ct: users,
    engagement: _total_engagement,
    total_engagement_score,
  } = summaryData;

  // const total_engagement = (selectedPlatforms.length === 1 && selectedPlatforms[0] === PLATFORMS.reddit)
  //     ? total_engagement_score
  //     : _total_engagement;
  const total_engagement = _total_engagement;

  const post_ct = posts || 0;
  const user_ct = users || 0;

  const allUserCohorts = lookupPlatformConfigItem(selectedPlatforms, "has_user_cohorts")
    ? userCohorts
        .map((cohort) => {
          const field = cohort.fullFieldName;
          const breakdownValues = getBreakdownValues(selectedPlatforms, summaryDataAllPlatforms, field);
          var value = aggregateBreakdownValues(selectedPlatforms, breakdownValues);
          return {
            breakdownValues: breakdownValues,
            percent: roundToOne((value / (post_ct || 1)) * 100),
            value: value,
            label: cohort.displayName,
            tooltipText: "",
          };
        })
        .sort((a, b) => {
          return b.value - a.value;
        })
    : [];

  const allUserGroups = (userGroups || []).map((field_name) => {
    const v = details[field_name] ?? null;
    return {
      breakdownValues: getBreakdownValues(selectedPlatforms, details, field_name),
      percent: user_ct && v ? getPercent(v, user_ct, 1) : null,
      value: v,
      label: field_name,
      hidePercentage: false,
    };
  });

  const authorGroupsChildren = totalUsers(selectedPlatforms);

  const authorStatRows = getAuthorStatRows({
    authorGroupsChildren,
    userGroups: allUserGroups,
    userCohorts: allUserCohorts,
  });

  const allEmotions = sortedDetails(
    selectedPlatforms,
    summaryData,
    emotions,
    post_ct,
    "return_name",
    true,
    details
  );
  const emotionsNotEmpty = allEmotions.some((d) => d.value > 0);

  const allSentiments = sortedDetails(
    selectedPlatforms,
    summaryData,
    sentiments,
    post_ct,
    "return_name",
    true,
    details
  );
  const sentimentsNotEmpty = allSentiments.some((d) => d.value > 0);

  const totalPostsData = totalPosts(selectedPlatforms, details);

  return [
    {
      name: "Posts",
      value: post_ct ? (
        <CountUp
          duration={countupSpeed}
          start={getStart(post_ct)}
          end={post_ct}
          separator={","}
        />
      ) : (
        "-"
      ),
      id: "totalPostsDashboard",
      tooltipText: null,
      icon: () => (
        <Box
          component="i"
          className="icon-posts"
          fontSize="1rem"
          sx={{ color: "#6F749C" }}
        />
      ),
      details: [
        {
          list: totalPostsData.map((d) => {
            const breakdownValues = getBreakdownValues(selectedPlatforms, details, d.return_name);
            const value = aggregateBreakdownValues(selectedPlatforms, breakdownValues);
            return {
              ...d,
              percent: post_ct && value ? getPercent(value, post_ct, 1) : null,
              value: value,
              breakdownValues: breakdownValues,
            };
          }),
        },
        {
          showDivider: true,
          name: "POST HIERARCHY",
          list: postHierarchy(selectedPlatforms).map((d) => {
            const breakdownValues = getBreakdownValues(selectedPlatforms, details, d.return_name);
            const total = aggregateBreakdownValues(selectedPlatforms, breakdownValues);
            return {
              ...d,
              field_name: d.field_name,
              percent: null,
              value: total,
              breakdownValues: breakdownValues,
            };
          }),
        },
      ],
    },
    ...(lookupPlatformConfigItem(selectedPlatforms, "has_engagements")
      ? [
          {
            name: "Engagements",
            value: total_engagement ? (
              <CountUp
                duration={countupSpeed}
                start={getStart(total_engagement)}
                end={total_engagement}
                separator={","}
              />
            ) : (
              "-"
            ),
            id: "totalEngagementsDashboard",
            tooltipText: lookupPlatformConfigItem("all", "engagement_tooltip"),
            icon: EngagementsIcon,
            details: [
              {
                list: totalEngagements(selectedPlatforms).map((d) => {
                  const field = d?.return_name ? d?.return_name : d?.field_name;
                  const breakdownValues = getBreakdownValues(selectedPlatforms, summaryDataAllPlatforms, field);
                  const total = aggregateBreakdownValues(selectedPlatforms, breakdownValues);
                  return {
                    ...d,
                    field_name: d.field_name,
                    percent:
                      total_engagement && total
                        ? getPercent(total, total_engagement, 1)
                        : null,
                    value: total,
                    breakdownValues: breakdownValues,
                  };
                }),
              },
            ],
          },
        ]
      : []),
    {
      name: USER_LABEL,
      value: user_ct ? (
        <CountUp
          duration={countupSpeed}
          start={getStart(user_ct)}
          end={user_ct}
          separator={","}
        />
      ) : (
        "-"
      ),
      id: "totalUserDashboard",
      tooltipText: null,
      icon: () => <AuthorsIcon />,

      details: [
        {
          list: authorGroupsChildren.map((d) => {
            const breakdownValues = getBreakdownValues(selectedPlatforms, details, d.return_name);
            const value = aggregateBreakdownValues(selectedPlatforms, breakdownValues);
            return {
              ...d,
              percent: user_ct && value ? getPercent(value, user_ct, 1) : null,
              value: value,
              breakdownValues: breakdownValues,
            };
          }),
        },
        {
          showDivider: authorGroupsChildren.length > 0,
          name: USER_COLUMN_LABEL + " Groups",
          viewMoreId: "userGroups",
          list: loading
            ? Array.from({ length: authorStatRows.userGroups.length })
            : authorStatRows.userGroups,
          items: allUserGroups,
          title: USER_LABEL + " | ALL",
          secondaryTitle: `Total ${USER_COLUMN_LABEL} Groups`,
          hiddenItems: Math.max(
            0,
            allUserGroups.length - authorStatRows.userGroups.length
          ),
        },
        {
          showDivider:
            authorGroupsChildren.length > 0 ||
            authorStatRows.userGroups.length > 0,
          name: "Cohorts",
          tooltipText: `Cohorts are tagged based on a users affiliation with a group or ideology on ${selectedPlatforms.join(", ")}`,
          info: `Cohorts are tagged based on a users affiliation with a group or ideology on ${selectedPlatforms.join(", ")}`,
          list: loading
            ? Array.from({ length: authorStatRows.userCohorts.length })
            : authorStatRows.userCohorts,
          viewMoreId: "userCohorts",
          title: "COHORTS | ALL",
          secondaryTitle: "Total cohorts",
          items: allUserCohorts,
          hiddenItems: Math.max(
            0,
            allUserCohorts.length - authorStatRows.userCohorts.length
          ),
        },
      ],
    },

    {
      name: "Sentiment",
      value: sentimentsNotEmpty
        ? `${allSentiments[0]?.percent}% ${allSentiments[0]?.label}`
        : "-",
      icon: UpDownIcon,
      tooltipText: null,
      details: [
        {
          list: allSentiments,
        },
      ],
      id: "Dashboard_Sentiment",
    },

    {
      name: "Emotions",
      value: emotionsNotEmpty
        ? `${allEmotions[0]?.percent}% ${allEmotions[0]?.label}`
        : "-",
      icon: EmotionIcon,
      tooltipText: null,
      details: [
        {
          list: loading
            ? Array.from({ length: STATS_ROW_LIMITS.emotions })
            : allEmotions.slice(0, STATS_ROW_LIMITS.emotions),

          viewMoreId: "emotions",
          title: "EMOTIONS",
          secondaryTitle: "Total emotions",
          items: allEmotions,
          hiddenItems: Math.max(
            0,
            allEmotions.length - STATS_ROW_LIMITS.emotions
          ),
        },
      ],
      id: "Dashboard_Emotions",
    },
  ];
};
