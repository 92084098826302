import { useEffect, useMemo, useState } from "react";
import { Box, Collapse, Grid, IconButton, useTheme } from "@mui/material";
import SummaryItem from "./summaryItem";
import {
  defaultReturnFields,
  emotions,
  getReturnFields,
  sentiments,
  totalUsersReturnField,
} from "utils/summaryReturnFields";
import { loadSummary, loadSummaryUserGroups } from "api/endpoints/AnalyticsApi";
import { getSummaries } from "./summaryConfig";
import { useAppContext } from "context/Context";
import useApi from "api/hooks/useApi";
import { QUERY_KEYS, ROUTE_PATHES } from "utils/constants";
import useEventTracker from "api/hooks/useEventTracker";
import useTabs from "hooks/useTabs";
import { useParams } from "react-router-dom";
import { mergeQueries } from "../../EntityExplorer/utils/EntityExplorerUtils";
import Details from "./Details";
import { ReactComponent as DownArrow } from "icons/UI/stats-down-arrow.svg";
import { ReactComponent as UpArrow } from "icons/UI/stats-up-arrow.svg";
import { getSummaryDataWithPlatform, mergeSummaryObjects } from "utils/summary";

// Constants for styling
const MIN_WIDTH = "150px";
const MAX_WIDTH = "400px";

// Function to create styles using theme and props
const useStyles = ({ theme, ...props }) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.$0,
    overflow: "auto",
    display: "flex",
    borderBottom: props.open ? "1px solid " + theme.palette.border.main : null,
  },
  content: {
    flexGrow: 1,
  },
  item: {
    flex: "1 1 0px",
    maxWidth: MAX_WIDTH,
    minWidth: MIN_WIDTH,
    height: "100%",
    padding: theme.spacing(0),
  },
  summaryItems: {
    width: "100%",
    alignItems: "flex-start",
    display: "flex",
    borderBottom: "1px solid " + theme.palette.border.main,
    paddingLeft: theme.spacing(1),
  },
  row: {
    minWidth: "30%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundColor: theme.palette.widget.main,
    height: "75px",
    flexGrow: 1,
  },
  toggler: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "76px",
    paddingRight: theme.spacing(3),
    borderBottom: "1px solid " + theme.palette.border.main,
  },
  togglerBtn: {
    width: "28px",
    height: "28px",
    borderRadius: "4px",
    padding: "2px",
    border: "1px solid " + theme.palette.border.main,
  },
  details: {
    width: "100%",
    height: "500px",
    overflowY: "auto",
    alignItems: "flex-start",
    display: "flex",
    paddingLeft: theme.spacing(1),
  },
  detail: {
    flex: "1 1 0px",
    minWidth: MIN_WIDTH,
    maxWidth: MAX_WIDTH,
    borderRight: "1px solid " + theme.palette.border.main,
    height: "100%",
    padding: theme.spacing(1, 0),
    "&:last-child": {
      "& .detail_row": {
        paddingRight: 0,
      },
      borderRight: "none",
    },
  },
});

export default function Summary() {
  // State to manage the visibility of details
  const [detailsOn, setDetailsOn] = useState(false);
  // State to store detailed data
  const [details, setDetails] = useState({});
  // Hook to track events
  const tracker = useEventTracker();
  // Hook to get the current path from tabs
  const { currentPath } = useTabs();
  // Hook to get URL parameters
  const { projectName } = useParams();

  // Destructuring state from context
  const {
    state: {
      analyzeSearch,
      narrative,
      start_date,
      end_date,
      selectedPlatforms,
      harmProfile,
      harmClassifier,
      userGroups,
      cohortList: topics,
      userCohorts,
      dataLoading,
      platform
    },
  } = useAppContext();

  // Memoized object for analyze filters
  const analyzeFiltersObj = useMemo(
    () =>
      narrative.analyze_filters ? narrative.analyze_filters.serialized : {},
    [narrative.analyze_filters]
  );

  // Memoized summary data for API request
  const summaryDataMemo = useMemo(() => {
    const analyzeFilters = narrative.analyze_filters?.serialized || {};

    const filters = mergeQueries(analyzeSearch, analyzeFilters);

    return {
      db: projectName,
      req: {
        start_date,
        end_date,
        platform: selectedPlatforms,
        latestKeywords: narrative?.keywords,
        ...filters,
        topic: narrative?.name,
        harmProfile,
        harmClassifier,
        returnFields: [...defaultReturnFields, ...emotions, ...sentiments],
      },
    };
  }, [
    analyzeSearch,
    start_date,
    end_date,
    selectedPlatforms,
    narrative.name,
    projectName,
    narrative.keywords,
    harmProfile,
    harmClassifier,
    narrative.analyze_filters?.serialized,
  ]);

  // API hook to load summary data
  const { data: summaryDataAllPlatforms, isLoading: summaryLoading } = useApi({
    apiKey: QUERY_KEYS.summary,
    payload: summaryDataMemo,
    apiFn: loadSummary,
    enabled: Boolean(
      !dataLoading &&
        !narrative.isDefault &&
        narrative.name &&
        selectedPlatforms?.length &&
        (currentPath === ROUTE_PATHES.overview ||
          currentPath === ROUTE_PATHES.analyze)
    ),
  });

  // Memoized detailed data for API request
  const detailedMemo = useMemo(() => {
    // Get the return fields for the platform
    const allReturnFields = getReturnFields(selectedPlatforms);

    const filters = mergeQueries(analyzeSearch, analyzeFiltersObj);
    return {
      db: projectName,
      req: {
        // instead of Promise.all, what if we unite returnFields like this?
        returnFields: allReturnFields,
        latestKeywords: narrative.keywords,
        ...filters,
        topic: narrative.name,
        start_date,
        end_date,
        ...(selectedPlatforms.length > 1 && { platform: selectedPlatforms }),
        harmProfile,
        harmClassifier,
      },
    };
  }, [
    harmProfile,
    harmClassifier,
    narrative.keywords,
    projectName,
    narrative.name,
    start_date,
    end_date,
    analyzeSearch,
    analyzeFiltersObj,
    selectedPlatforms,
  ]);

  // API hook to load detailed data
  const { data: allDetails, isLoading: loading } = useApi({
    apiKey: QUERY_KEYS.summary,
    apiFn: loadSummary,
    payload: detailedMemo,
    enabled: detailsOn && !dataLoading,
  });

  // Memoized user group names
  const userGroupNames = useMemo(() => {
    if (analyzeFiltersObj.user_group_names) {
      return analyzeFiltersObj.user_group_names;
    }
    return userGroups.map((d) => d.name);
  }, [userGroups, analyzeFiltersObj.user_group_names]);

  // Memoized details by user group
  const detailsByUGMemo = useMemo(() => {
    const filters = mergeQueries(analyzeSearch, analyzeFiltersObj);

    return {
      db: projectName,
      requests: userGroupNames.map((group) => ({
        returnFields: totalUsersReturnField,
        latestKeywords: narrative.keywords,
        ...filters,
        topic: narrative.name,
        start_date,
        end_date,
        platform: selectedPlatforms,
        harmProfile,
        harmClassifier,
        user_group_names: [group],
      })),
    };
  }, [
    selectedPlatforms,
    start_date,
    end_date,
    harmProfile,
    harmClassifier,
    projectName,
    narrative.name,
    narrative.keywords,
    analyzeSearch,
    analyzeFiltersObj,
    userGroupNames,
  ]);

  // API hook to load details by user group
  const { data: detailsByUG } = useApi({
    apiKey: QUERY_KEYS.summary + "user_groups",
    apiFn: loadSummaryUserGroups,
    payload: detailsByUGMemo,
    enabled: detailsOn && userGroupNames.length > 0 && !dataLoading,
  });

  // Get summaries for display
  const summaries = getSummaries({
    selectedPlatforms: selectedPlatforms || [],
    summaryDataAllPlatforms: summaryDataAllPlatforms || {},
    details,
    loading,
    userGroups: userGroupNames,
    topics,
    userCohorts,
  });
  
  // Get theme and styles
  const theme = useTheme();
  const styles = useStyles({
    theme,
    open: detailsOn,
  });

  // Effect to update details when data changes
  useEffect(() => {
    let newDetails = {};

    // Merge summary data for all platforms
    if(summaryDataAllPlatforms) {
      newDetails = mergeSummaryObjects(summaryDataAllPlatforms, newDetails);
    }

    // Merge details by user group
    if (detailsByUG) {
      newDetails = mergeSummaryObjects(newDetails, detailsByUG);
    }

    // If allDetails is present, update newDetails with the detailed data
    if (allDetails && detailedMemo.req) {
      const platformDetails = getSummaryDataWithPlatform(
        allDetails,
        selectedPlatforms
      );
      
      // Merge detailed data with summary data
      newDetails = mergeSummaryObjects(allDetails, newDetails);
      
      // Merge return fields with detailed data
      detailedMemo.req.returnFields.forEach(({ field_name, return_name }) => {
        Object.keys(newDetails).forEach((platform) => {
          newDetails[platform][field_name] = platformDetails[return_name];
        });
      });
    }

    setDetails(newDetails);
  }, [detailsByUG, allDetails, summaryDataAllPlatforms, selectedPlatforms, detailedMemo]);

  // Effect to reset details visibility when path changes
  useEffect(() => {
    setDetailsOn(false);
  }, [currentPath]);

  // Function to toggle details visibility
  const toggleDetails = () => {
    tracker.track(
      `Expanded detailed stats [${detailsOn ? "hide" : "show"}]`,
      detailsOn ? "Collapse" : "Expand",
      detailsOn ? "Collapse" : "Expand",
      "Detailed stats widget"
    );
    setDetailsOn(!detailsOn);
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.content}>

        {/* Display the summary items */}
        <Grid
          container
          alignItems="center"
          wrap="nowrap"
          sx={styles.summaryItems}
        >
          {summaries.map((s, i) => (
            <Grid item key={i} sx={styles.item}>
              <SummaryItem
                {...s}
                isLast={i === summaries.length - 1}
                loading={summaryLoading}
              />
            </Grid>
          ))}
        </Grid>

        {/* Display the detailed data */}
        <Collapse in={detailsOn}>
          <Box sx={styles.details}>
            {summaries.map(({ details = [] }, i) => (
              <Box key={i} sx={styles.detail}>
                {details.map((detail, i) => (
                  <Details key={i} {...detail} loading={loading} />
                ))}
              </Box>
            ))}
          </Box>
        </Collapse>
      </Box>
      <Box sx={styles.toggler}>
        <IconButton
          onClick={toggleDetails}
          sx={styles.togglerBtn}
          id="ShowDetailedStats"
        >
          {detailsOn ? <UpArrow /> : <DownArrow />}
        </IconButton>
      </Box>
    </Box>
  );
}
