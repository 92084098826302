import {
  ALL_PROJECT,
  DEFAULT_CLASSIFIER,
  DEFAULT_NARRATIVE,
  DEFAULT_PROFILE,
  DEFAULT_SORT,
  getSelectedPlatformsAsList,
  lookupPlatformConfigItem,
  PLATFORM_CONFIG,
} from "utils/constants";
import { DEFAULT_END_DATE, DEFAULT_START_DATE } from "utils/format";
import { getNarrativeData } from "./reducerUtilities.js";

export const initialState = {
  dataLoading: false, // strapi data being loaded, project, cohorts, narratives, platforms, etc
  datasetUpdateRequired: false, // Indicates whether a change in the system state demands a full refresh of the dataset.

  // Platforms related fields
  platform: "all", // Either 'all' or ['reddit', 'twitter']
  platforms: [], // Available platforms as array of objects [{ value: 'twitter', value: 'reddit' } }
  selectedPlatforms: [], // This should hold selected platform names: ['twitter', 'reddit', 'news'].

  // Project related fields
  projects: [],
  projectsLoading: false,
  project: {},

  // Narrative related fields
  narratives: [],
  narrativeClusters: [],
  narrative: DEFAULT_NARRATIVE,
  loadingNarratives: true,
  isSmallScreen: false,

  // Fields related to Sort
  analyzeSortField: DEFAULT_SORT,
  analyzeSortDir: "DESC",
  analyzeEntity: null,

  // Date related fields
  spike_date: null,
  start_date: DEFAULT_START_DATE,
  end_date: DEFAULT_END_DATE,
  min_date: new Date(2000, 0, 1).toISOString(),
  max_date: new Date().toISOString(),
  timezoneOffset: 0, // UTC
  timezoneName: "",
  timezoneDescr: "",

  keywords: "", // keywords from narrative, shared across multiple tabs
  showStats: false,
  isBase: true,
  analyzeSearch: {},
  user: {},

  //visualize
  visualizeDrawerOpen: false,
  visualizeDrawerText: "",
  visualizeGraphName: "",
  visualizeConcepts: null,
  visualizeNarratives: [],
  visualizeEntityTypes: [],
  visualizeActiveVizID: "",
  visualizeDrawerMode: "",

  //Confiuration state
  configuring: false,
  editing: false,
  customConfig: false,

  //Visualization configuration
  graphName: "",
  vizNarratives: [],

  //Visualizations
  datasets: [],
  recently_added_datasets: [],

  projectSettings: null,
  cohortNames: [],
  cohortList: [],
  userCohorts: [],

  // harm drawer
  harmDrawerOpen: false,
  harmProfiles: [DEFAULT_PROFILE],
  harmProfile: DEFAULT_PROFILE,
  harmProfileEdit: {},

  // harm classifier drawer
  harmClassifierOpen: false,
  harmClassifiers: [DEFAULT_CLASSIFIER],
  harmClassifier: DEFAULT_CLASSIFIER,
  harmClassifierEdit: {},

  // user groups
  userGroupDrawerOpen: false,
  userGroups: [],
  userGroupEdit: {},

  // snackbar message
  snackbarMessage: null,

  languages: [],

  unsupportedFeatures: {},

  statsDrawerData: null,

  dynamic_filters: null,

  modelLabels: [],

  chartMetric: 'total',
  chartNormalize: false,
  chartStacked: false,
};

export const ACTIONS = {
  SET_DATA_LOADING: "SET_DATA_LOADING",
  LOGOUT: "LOGOUT",
  SET_USER: "SET_USER",
  SET_TIMEZONE: "SET_TIMEZONE",
  SET_IS_BASE: "SET_IS_BASE",
  SET_SHOW_STATS: "SET_SHOW_STATS",
  SET_ANALYZE_SEARCH: "SET_ANALYZE_SEARCH",
  SET_ANALYZE_ENTITY: "SET_ANALYZE_ENTITY",
  SET_PROJECTS: "SET_PROJECTS",
  SET_PROJECTS_LOADING: "SET_PROJECTS_LOADING",
  SET_UNSUPPORTED_FEATURE: "SET_UNSUPPORTED_FEATURE",
  SELECT_PROJECT: "SELECT_PROJECT",
  SET_PROJECT_DYNAMIC_FILTERS: "SET_PROJECT_DYNAMIC_FILTERS",
  SET_LOADED_NARRATIVES: "SET_LOADED_NARRATIVES",
  SET_NARRATIVES: "SET_NARRATIVES",
  SET_SIDEBAR_TAB: "SET_SIDEBAR_TAB",
  SELECT_NARRATIVE: "SELECT_NARRATIVE",
  SET_SPIKE_DATE: "SET_SPIKE_DATE",
  ADD_NARRATIVE: "ADD_NARRATIVE",
  UPDATE_NARRATIVE: "UPDATE_NARRATIVE",
  REMOVE_NARRATIVE: "REMOVE_NARRATIVE",
  SET_DATES: "SET_DATES",
  SET_MIN_MAX_DATES: "SET_MIN_MAX_DATES",
  SET_NARRATIVE_CLUSTERS: "SET_NARRATIVE_CLUSTERS",
  SET_PLATFORM: "SET_PLATFORM",
  SET_PLATFORMS: "SET_PLATFORMS",
  CLOSE_VISUALIZE_DRAWER: "CLOSE_VISUALIZE_DRAWER",
  OPEN_VISUALIZE_DRAWER: "OPEN_VISUALIZE_DRAWER",
  EDIT_VISUALIZATION: "EDIT_VISUALIZATION",
  SET_ANALIZE_SORT: "SET_ANALIZE_SORT",
  SET_HARM_DRAWER_OPEN: "SET_HARM_DRAWER_OPEN",
  SET_HARM_PROFILE: "SET_HARM_PROFILE",
  ADD_HARM_PROFILE: "ADD_HARM_PROFILE",
  SET_HARM_PROFILES: "SET_HARM_PROFILES",
  REMOVE_HARM_PROFILE: "REMOVE_HARM_PROFILE",
  EDIT_HARM_PROFILE: "EDIT_HARM_PROFILE",
  UPDATE_HARM_PROFILE: "UPDATE_HARM_PROFILE",
  SET_HARM_CLASSIFIER_OPEN: "SET_HARM_CLASSIFIER_OPEN",
  SET_HARM_CLASSIFIER: "SET_HARM_CLASSIFIER",
  ADD_HARM_CLASSIFIER: "ADD_HARM_CLASSIFIER",
  SET_HARM_CLASSIFIERS: "SET_HARM_CLASSIFIERS",
  REMOVE_HARM_CLASSIFIER: "REMOVE_HARM_CLASSIFIER",
  EDIT_HARM_CLASSIFIER: "EDIT_HARM_CLASSIFIER",
  UPDATE_HARM_CLASSIFIER: "UPDATE_HARM_CLASSIFIER",
  SET_USER_GROUPS_DRAWER_OPEN: "SET_USER_GROUPS_DRAWER_OPEN",
  SET_USER_GROUPS: "SET_USER_GROUPS",
  ADD_USER_GROUP: "ADD_USER_GROUP",
  REMOVE_USER_GROUP: "REMOVE_USER_GROUP",
  EDIT_USER_GROUP: "EDIT_USER_GROUP",
  UPDATE_USER_GROUP: "UPDATE_USER_GROUP",

  SHOW_MESSAGE: "SHOW_MESSAGE",
  SET_COHORTS: "SET_COHORTS",
  SET_LANGUAGES: "SET_LANGUAGES",
  SET_PROJECT_DOCUMENT_COUNT: "SET_PROJECT_DOCUMENT_COUNT",

  SET_STATS_DRAWER_OPEN: "SET_STATS_DRAWER_OPEN",

  UPDATE_DEFAULT_FILTER: "UPDATE_DEFAULT_FILTER",

  SET_VIZ_NARRATIVES: "SET_VIZ_NARRATIVES",
  SET_GRAPH_NAME: "SET_GRAPH_NAME",
  SYNC_DATASETS: "SYNC_DATASETS",
  SET_DATASETS: "SET_DATASETS",
  REMOVE_VISUALIZATION: "REMOVE_VISUALIZATION",
  ADD_VISUALIZATION: "ADD_VISUALIZATION",
  SET_MODEL_LABELS: "SET_MODEL_LABELS",

  SET_CHART_METRIC: 'SET_CHART_METRIC',
  SET_CHART_NORMALIZE: 'SET_CHART_NORMALIZE',
  SET_CHART_STACKED: 'SET_CHART_STACKED' 
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  // Reset the value so that each type decides how to handle it.
  state.datasetUpdateRequired = false;

  switch (type) {
    case ACTIONS.LOGOUT:
      return initialState;

    case ACTIONS.SET_DATA_LOADING: {
      return {
        ...state,
        dataLoading: payload,
      };
    }

    case ACTIONS.SET_PLATFORM: {
      const projectSettings = state.project.projectConfig;
      const platform = payload || 'all';

      const selectedPlatforms = getSelectedPlatformsAsList(
        platform,
        state.platforms
      );

      return {
        ...state,

        // PREDASH-4205
        analyzeSortField: lookupPlatformConfigItem(platform, "has_engagements")
          ? DEFAULT_SORT
          : "post_ct",
        analyzeSortDir: "DESC",
        platform,
        selectedPlatforms,
        projectSettings,
      };
    }
    case ACTIONS.SET_PLATFORMS: {
      const platforms = (payload || []).map((d) => ({
        disabled: false,
        ...PLATFORM_CONFIG[d.name],
      }));

      return {
        ...state,
        platforms,
      };
    }

    // Dynamic filters is misnomer, it should just be called filters.
    // Dynamic filter means that options are loaded dynamically.
    case ACTIONS.SET_PROJECT_DYNAMIC_FILTERS: {
      if(!payload) return state;
      // Check that payload is an array
      if(!Array.isArray(payload)) return state;

      const new_dynamic_filters = payload.map((df) => ({
        // Filter field
        field: df?.field?.constellation_field || `custom_filter${df.id}`,

        // Real ES field
        elasticSearchField: df?.field?.elastic_search_field,

        // Filter name
        title: df.display_name,

        // Parent filters concatenate filter field and value with .
        // Like this: dynamicFilterField += "." + value;
        isParent: !!df?.field?.is_parent,

        // customEndpoint no longer used, but still supported in strapi and code.
        customEndpoint: df.custom_endpoint,

        // Filter type: min_max (for range filters), category and custom
        type: df.filter_type,

        // If not is_dynamic, it defines selection type: { bot_like: true } or ['bot_like']
        // If is_dynamic, check this in pigeon: https://github.com/blackbirdai-team/constellation-pigeon/blob/c461dfe681f7bfae34f475322ef62901cc786260/src/elasticsearch/getQuery.js#L87
        boolType: df.bool_type,

        // Show or hide document count
        showDocumentCount: df.show_document_count,

        // Some values to exclude from the list
        excludedValues: df.excluded_values ? df.excluded_values.split(",") : [],

        // Tunable field reads from runtime fields
        // https://github.com/blackbirdai-team/constellation-pigeon/blob/c461dfe681f7bfae34f475322ef62901cc786260/src/elasticsearch/getQuery.js#L230
        isTunable: !!df?.field?.is_tunable,

        // format label or not, heavily used for Cohorts
        isFormatted: df.is_formatted,

        // Limit options
        maxOptions: df.max_filter_options,

        // Filter options
        options: (df.custom_options || []).map((o) => {
          const platforms = o.platforms.map((d) => d.name);
          return {
            ...o,
            platforms,
            elasticSearchField: o.field.elastic_search_field,
            field: o.field.constellation_field,
          };
        }),

        // Which platforms is this filter for. If empty, it is considered as general filter and always shown
        platforms: df.platforms,

        // Nests filter inside this field
        requestPropertyGroup: df.request_field,

        // Just visual group in filter popup.
        uiGroup: df.ui_group?.toLowerCase(),

        // If true, it will send this filter under `dynamicFilters` field and handled differently
        // Dynamic filters load options from ES
        isDynamic: df.is_dynamic,
      }));
      return {
        ...state,
        dynamic_filters: new_dynamic_filters,
      };
    }
    case ACTIONS.SET_USER:
      return {
        ...state,
        user: payload || {},
      };
    case ACTIONS.SET_PROJECT_DOCUMENT_COUNT:
      return {
        ...state,
        projectDocumentCount: payload,
      };

    case ACTIONS.SET_TIMEZONE:
      return {
        ...state,
        timezoneOffset: payload.Offset || 0,
        timezoneName: payload.Name || "",
        timezoneDescr: payload.Description || "",
      };
    case ACTIONS.SET_SMALL_SCREEN:
      return {
        ...state,
        isSmallScreen: payload,
      };

    case ACTIONS.SET_IS_BASE:
      return {
        ...state,
        isBase: payload,
      };
    case ACTIONS.SET_NARRATIVE_CLUSTERS:
      return {
        ...state,
        narrativeClusters: payload,
      };
    case ACTIONS.SET_SHOW_STATS:
      return {
        ...state,
        showStats: payload,
      };

    case ACTIONS.SET_ANALIZE_SORT:
      return {
        ...state,
        analyzeSortField: payload.analyzeSortField,
        analyzeSortDir: payload.analyzeSortDir,
      };

    case ACTIONS.SET_ANALYZE_SEARCH:
      return {
        ...state,
        analyzeSearch: payload,
      };

    case ACTIONS.SET_ANALYZE_ENTITY:
      return {
        ...state,
        analyzeEntity: payload,
      };

    case ACTIONS.SET_PROJECTS:
      return {
        ...state,
        projects: payload || [],
      };

    case ACTIONS.SET_PROJECTS_LOADING:
      return {
        ...state,
        projectsLoading: payload,
      };

    case ACTIONS.SET_UNSUPPORTED_FEATURE:
      return {
        ...state,
        unsupportedFeatures: {
          ...state.unsupportedFeatures,
          [payload.featureName]: !payload.supported,
        },
      };

    case ACTIONS.SELECT_PROJECT: {
      const projectSettings = payload?.projectConfig;
      const harmClassifier = payload?.classifierProfile || DEFAULT_CLASSIFIER;

      return {
        ...state,
        project: payload,
        spike_date: null,
        harmClassifier,
        projectSettings,
      };
    }

    case ACTIONS.SET_LOADED_NARRATIVES: {
      const narratives = (payload || []).map((n) => {
        if (!n.analyzeFilters) {
          n.analyzeFilters = DEFAULT_NARRATIVE.analyze_filters;
        }

        return {
          ...n,
          analyze_filters: n.analyzeFilters,
          concepts: n.concepts || [],
          start_date: n.startDate,
          end_date: n.endDate,
          narrativeId: n.id,
        };
      });

      return {
        ...state,
        narratives,
      };
    }

    case ACTIONS.SET_NARRATIVES: {
      const { narratives, narrativeIndex = 0 } = payload;
      const _narrative = narratives.length
        ? narratives[narrativeIndex]
        : DEFAULT_NARRATIVE;

      if (!_narrative.analyze_filters) {
        _narrative.analyze_filters = DEFAULT_NARRATIVE.analyze_filters;
      }

      return {
        ...state,
        narratives,
        narrative: _narrative,
        start_date: narratives.length
          ? narratives[narrativeIndex].start_date
          : DEFAULT_START_DATE,
        end_date: narratives.length
          ? narratives[narrativeIndex].end_date
          : DEFAULT_END_DATE,
        loadingNarratives: false,
      };
    }
    case ACTIONS.SET_SPIKE_DATE: {
      if (!payload) {
        return {
          ...state,
          spike_date: null,
        };
      }
      const date = payload.split("T")[0];
      return {
        ...state,
        spike_date: payload,
        start_date: date + "T00:00:00.000Z",
        end_date: date + "T23:59:59.000Z",
      };
    }

    case ACTIONS.SELECT_NARRATIVE: {
      const narrativeData = getNarrativeData(state.narratives, payload);

      return {
        ...state,
        ...narrativeData,
      };
    }

    case ACTIONS.ADD_NARRATIVE: {
      const narrative = { ...payload };
      const newNarratives = [...state.narratives];
      newNarratives.push(narrative);

      return {
        ...state,
        narratives: newNarratives,
        narrative,
        start_date: narrative.start_date,
        end_date: narrative.end_date,
      };
    }

    case ACTIONS.UPDATE_DEFAULT_FILTER: {
      const analyze_filters = { ...payload };
      const { narrative, narratives } = state;

      // Update current narrative
      const newNarrative = { ...narrative };

      if (newNarrative.name === ALL_PROJECT) {
        newNarrative.analyzeFilters = analyze_filters;
        newNarrative.analyze_filters = analyze_filters;
      }

      // Update All narrative to have default filters
      const newNarratives = narratives.map((d) => {
        if (d.name === ALL_PROJECT) {
          return {
            ...d,
            analyze_filters,
            analyzeFilters: analyze_filters,
          };
        }
        return d;
      });

      return {
        ...state,
        narrative: newNarrative,
        narratives: newNarratives,
      };
    }

    case ACTIONS.UPDATE_NARRATIVE: {
      const { narrative: newNarrative, index } = payload;
      const updatedNarratives = [...state.narratives];
      updatedNarratives[index] = newNarrative;

      return {
        ...state,
        narratives: updatedNarratives,
        narrative: newNarrative,
      };
    }

    case ACTIONS.REMOVE_NARRATIVE: {
      const narrativesAfterRemove = [...state.narratives];
      narrativesAfterRemove.splice(payload, 1);

      return {
        ...state,
        narratives: narrativesAfterRemove,
      };
    }

    case ACTIONS.SET_DATES:
      return {
        ...state,
        start_date: payload.start,
        end_date: payload.end,
      };

    case ACTIONS.SET_MIN_MAX_DATES:
      return {
        ...state,
        min_date: payload.min_date,
        max_date: payload.max_date,
      };

    case ACTIONS.CLOSE_VISUALIZE_DRAWER:
      return {
        ...state,
        visualizeDrawerOpen: payload.visualizeDrawerOpen,
        visualizeGraphName: "",
        visualizeConcepts: null,
        visualizeDrawerMode: "",
        visualizeEntityTypes: [],
        visualizeNarratives: [],
        visualizeActiveVizID: "",
        datasetUpdateRequired: payload.datasetUpdateRequired,
      };

    case ACTIONS.OPEN_VISUALIZE_DRAWER:
      return {
        ...state,
        visualizeDrawerOpen: payload.visualizeDrawerOpen,
        visualizeDrawerText: payload.visualizeDrawerText,
        visualizeDrawerMode: payload.visualizeDrawerMode,
        visualizeGraphName: "",
        visualizeConcepts: null,
        visualizeEntityTypes: [],
        visualizeNarratives: [],
        visualizeActiveVizID: "",
      };

    case ACTIONS.EDIT_VISUALIZATION:
      return {
        ...state,
        datasetUpdateRequired: true,
        visualizeDrawerOpen: true,
        visualizeDrawerMode: payload.visualizeDrawerMode,
        visualizeDrawerText: payload.visualizeDrawerText,
        visualizeGraphName: payload.visualizeGraphName,
        visualizeConcepts: payload.visualizeConcepts,
        visualizeEntityTypes: payload.visualizeEntityTypes,
        visualizeNarratives: payload.visualizeNarratives,
        visualizeActiveVizID: payload.visualizeActiveVizID,
      };

    // HARM PROFILE

    case ACTIONS.SET_HARM_DRAWER_OPEN:
      return {
        ...state,
        harmDrawerOpen: payload,
      };

    case ACTIONS.SET_HARM_PROFILE:
      return {
        ...state,
        harmProfile: payload,
      };

    case ACTIONS.SET_HARM_PROFILES:
      return {
        ...state,
        harmProfiles: payload,
      };

    case ACTIONS.ADD_HARM_PROFILE: {
      const profiles = [...state.harmProfiles];
      profiles.push(payload);
      return {
        ...state,
        harmProfiles: profiles,
        harmProfileEdit: {},
      };
    }
    case ACTIONS.REMOVE_HARM_PROFILE: {
      const profiles = [...state.harmProfiles];
      const index = profiles.findIndex((d) => d.id === payload.id);
      profiles.splice(index, 1);
      return {
        ...state,
        harmProfiles: profiles,
        harmProfile: profiles[0],
      };
    }

    case ACTIONS.UPDATE_HARM_PROFILE: {
      const profiles = [...state.harmProfiles];
      const index = profiles.findIndex((d) => d.id === payload.id);
      profiles[index] = payload;
      return {
        ...state,
        harmProfiles: profiles,
        harmProfileEdit: {},
      };
    }

    case ACTIONS.EDIT_HARM_PROFILE:
      return {
        ...state,
        harmProfileEdit: payload,
        harmDrawerOpen: true,
      };

    // HARM CLASSIFIER

    case ACTIONS.SET_HARM_CLASSIFIER_OPEN:
      return {
        ...state,
        harmClassifierOpen: payload,
      };

    case ACTIONS.SET_HARM_CLASSIFIER:
      return {
        ...state,
        harmClassifier: payload,
      };

    case ACTIONS.SET_HARM_CLASSIFIERS:
      return {
        ...state,
        harmClassifiers: payload,
      };

    case ACTIONS.ADD_HARM_CLASSIFIER: {
      const profiles = [...state.harmClassifiers];
      profiles.push(payload);

      return {
        ...state,
        harmClassifiers: profiles,
        harmClassifierEdit: {},
      };
    }
    case ACTIONS.REMOVE_HARM_CLASSIFIER: {
      const profiles = [...state.harmClassifiers];
      const index = profiles.findIndex((d) => d.id === payload.id);
      profiles.splice(index, 1);
      return {
        ...state,
        harmClassifiers: profiles,
        harmClassifier: profiles[0],
      };
    }

    case ACTIONS.UPDATE_HARM_CLASSIFIER: {
      const profiles = [...state.harmClassifiers];
      const index = profiles.findIndex((d) => d.id === payload.id);
      profiles[index] = payload;
      return {
        ...state,
        harmClassifiers: profiles,
        harmClassifierEdit: {},
      };
    }

    case ACTIONS.EDIT_HARM_CLASSIFIER:
      return {
        ...state,
        harmClassifierEdit: payload,
        harmClassifierOpen: true,
      };

    // USER GROUPS

    case ACTIONS.SET_USER_GROUPS_DRAWER_OPEN:
      return {
        ...state,
        userGroupDrawerOpen: payload,
      };

    case ACTIONS.SET_USER_GROUPS:
      return {
        ...state,
        userGroups: payload,
      };

    case ACTIONS.ADD_USER_GROUP: {
      const groups = [...state.userGroups];
      groups.push(payload);

      return {
        ...state,
        userGroups: groups,
        userGroupEdit: {},
      };
    }

    case ACTIONS.REMOVE_USER_GROUP: {
      const groups = [...state.userGroups];
      const index = groups.findIndex((d) => d.id === payload.id);
      groups.splice(index, 1);
      return {
        ...state,
        userGroups: groups,
      };
    }

    case ACTIONS.UPDATE_USER_GROUP: {
      const groups = [...state.userGroups];
      const index = groups.findIndex((d) => d.id === payload.id);
      groups[index] = payload;
      return {
        ...state,
        userGroups: groups,
        userGroupEdit: {},
      };
    }

    case ACTIONS.EDIT_USER_GROUP:
      return {
        ...state,
        userGroupEdit: payload,
        userGroupDrawerOpen: true,
      };

    case ACTIONS.SHOW_MESSAGE:
      return {
        ...state,
        snackbarMessage: payload,
      };

    case ACTIONS.SET_COHORTS: {
      return {
        ...state,
        cohortNames: payload.cohortNames,
        cohortList: payload.cohortList,
        userCohorts: payload.userCohorts,
      };
    }
    case ACTIONS.SET_LANGUAGES:
      return {
        ...state,
        languages: payload,
      };

    case ACTIONS.SET_STATS_DRAWER_OPEN:
      return {
        ...state,
        statsDrawerData: payload,
      };

    // VISUALIZE
    case ACTIONS.SET_DATASETS: {
      return {
        ...state,
        datasets: payload,
      };
    }

    case ACTIONS.SET_VIZ_NARRATIVES: {
      return {
        ...state,
        vizNarratives: payload,
      };
    }

    case ACTIONS.SYNC_DATASETS: {
      const dequeued_recently_added = state.recently_added_datasets.filter(
        ({ id, name }) =>
          !payload.find(
            (ds) => ds.name === name && ds.start_time === id.toString()
          )
      );

      const up_to_date_visualizations = [
        ...payload,
        ...dequeued_recently_added,
      ];

      return {
        ...state,
        datasets: up_to_date_visualizations,
        recently_added_datasets: dequeued_recently_added,
      };
    }

    case ACTIONS.REMOVE_VISUALIZATION:
      return {
        ...state,
        datasets: payload.datasets,
      };

    case ACTIONS.ADD_VISUALIZATION:
      return {
        ...state,
        configuring: false,
        editing: false,
        customConfig: false,
        datasets: payload.datasets,
        recently_added_datasets: [
          ...state.recently_added_datasets,
          payload.new_dataset,
        ],
        datasetUpdateRequired: true,
      };
    case ACTIONS.SET_MODEL_LABELS: {
      return {
        ...state,
        modelLabels: payload,
      };
    }

    case ACTIONS.SET_CHART_METRIC: {
      return {
        ...state,
        chartMetric: payload
      }
    }

    case ACTIONS.SET_CHART_NORMALIZE: {
      return {
        ...state,
        chartNormalize: payload
      }
    }

    case ACTIONS.SET_CHART_STACKED: {
      return {
        ...state,
        chartStacked: payload
      }
    }

    // END OF VISUALIZE

    default:
      return state;
  }
};

export default reducer;
