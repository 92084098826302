import { TextField } from "@mui/material";

export default function InputValidate(props) {
  const {
    emptyWarning = "",
    existsWarning = "",
    placeholder = "",
    label,
    exists,
    showError, // force to show error
    disabled,
    onChange,
    maxLength,
  } = props;

  const helperText =
    showError && props.value === ""
      ? emptyWarning
      : exists
      ? existsWarning
      : null;

  return (
    <TextField
      disabled={disabled}
      value={props.value}
      error={!!helperText}
      helperText={helperText}
      variant="outlined"
      size="medium"
      label={label}
      placeholder={placeholder}
      onChange={onChange}
      InputLabelProps={{ shrink: true }}
      InputProps={{ disableUnderline: true }}
      inputProps={{ maxLength }}
      fullWidth
      {...props.fieldProps}
    />
  );
}
