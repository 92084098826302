import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import { useAppContext } from "context/Context";
import { prepareBreakdownValuesWithPercent } from "utils/summary";

// Child Summary Platform Breakdown component that displays the breakdown data for platforms
export default function ChildSummaryPlatformBreakdown({ title, breakdownValues }) {
  const theme = useTheme();
  const {
    state: { selectedPlatforms },
  } = useAppContext();

  return (
    <Box sx={{ position: "absolute", backgroundColor: theme.palette.background.$1, padding: theme.spacing("16px"), borderRadius: "8px", border: `${theme.palette.border.widget}`, zIndex: 1000 }} >
      <Typography sx={{ textAlign: "center", paddingBottom: "16px", fontSize: "12px", fontWeight: "700", color: theme.palette.text.primary }} data-testid="child-summary-platform-breakdown-title">
        {title} breakdown
      </Typography>
      <Box>
        <Table data-testid="child-summary-platform-breakdown-table">
            <TableHead sx={{ paddingBottom: theme.spacing("4px") }}>
                <TableRow>
                    <TableCell sx={{ textAlign: "left", paddingTop: "0px", paddingBottom: "8px", paddingLeft: "0px", color: theme.palette.text.muted }} data-testid="child-summary-platform-breakdown-platform-header">Platform</TableCell>
                    <TableCell sx={{ textAlign: "left", paddingLeft: "0px", paddingTop: "0px", paddingBottom: "8px", color: theme.palette.text.muted }} data-testid="child-summary-platform-breakdown-total-header">Total</TableCell>
                    <TableCell sx={{ textAlign: "right", paddingTop: "0px", paddingBottom: "8px", color: theme.palette.text.muted }} data-testid="child-summary-platform-breakdown-percent-header">%</TableCell>
                </TableRow>
            </TableHead>
            <TableBody sx={{ paddingTop: theme.spacing("8px") }}>
                {prepareBreakdownValuesWithPercent(selectedPlatforms, breakdownValues).map(({platform, value, percent}, i) => (
                    <TableRow sx={{ borderBottom: `0px` }} key={i}>
                        <TableCell sx={{ textAlign: "left", paddingTop: "8px", paddingBottom: "0px", paddingLeft: "0px", color: theme.palette.text.muted, borderBottom: `0px` }} data-testid="child-summary-platform-breakdown-platform-value">{platform}</TableCell>
                        <TableCell sx={{ textAlign: "left", paddingTop: "8px", paddingBottom: "0px", paddingLeft: "0px", color: theme.palette.text.primary, borderBottom: `0px` }} data-testid="child-summary-platform-breakdown-total-value">{value ? value.toLocaleString() : "-"}</TableCell>
                        <TableCell sx={{ textAlign: "right", paddingTop: "8px", paddingBottom: "0px", paddingLeft: "0px", color: theme.palette.text.muted, borderBottom: `0px` }} data-testid="child-summary-platform-breakdown-percent-value">{percent !== null ? `${percent}%` : "-"}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
      </Box>
    </Box>
  );
}
